const { compiler } = require('markdown-to-jsx')

const WP_COMPONENTS = ['RelatedPosts', 'NewsList']

module.exports.getTagsInContent = content => {
  if (content) {
    const component = compiler(content)
    const children = component?.props?.children

    if (!Array.isArray(children)) {
      return []
    }

    return children
      .filter(({ type }) => WP_COMPONENTS.includes(type))
      .reduce((previous, child) => {
        const tag = child?.props?.tag
        if (!tag) {
          return previous
        }

        return [...previous, tag]
      }, [])
  }

  return []
}
