import Post from '@pretto/website/src/postTypes/post/post'
import formatInitData from '@pretto/website/src/templates/blocs/lib/formatInitData'
import formatWpResultToGatsbyResult from '@pretto/website/src/templates/blocs/lib/formatWpResultToGatsbyResult'
import { getBlocData } from '@pretto/website/src/templates/blocs/lib/getBlocData'
import { getCategoryData } from '@pretto/website/src/templates/blocs/lib/getCategoryData'
import { getCategoryId } from '@pretto/website/src/templates/blocs/lib/getCategoryId'
import { getInitData } from '@pretto/website/src/templates/blocs/lib/getInitData'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import qs from 'qs'
import { useEffect, useState } from 'react'

const PreviewPostBlocs = ({ location, data: { rates } }) => {
  const [data, setData] = useState(null)
  let articlesData = null

  useEffect(() => {
    const { id, nonce } = qs.parse(location.search, { ignoreQueryPrefix: true })

    ;(async () => {
      const initData = await getInitData({ id, nonce })
      const formatedInitData = formatInitData(initData.data.post)

      if (formatedInitData.templateBlocsArticlesCategory) {
        const categoriesIdResults = await getCategoryId({
          categoriesSlug: formatedInitData.templateBlocsArticlesCategory,
          nonce,
        })

        const categoriesId = categoriesIdResults.data.categories.nodes.map(categorie => categorie.databaseId)

        const categoriesDataResult = await Promise.all(
          categoriesId.map(async id => {
            const result = await getCategoryData({
              categorysId: id,
              nonce,
            })

            return result.data
          })
        )

        articlesData = categoriesDataResult.reduce(
          (prev, current) => ({
            articles: { nodes: [...prev.articles.nodes, ...current.articles.nodes] },
            articlesCategory: { nodes: [...prev.articlesCategory.nodes, current.articlesCategory] },
          }),
          { articles: { nodes: [] }, articlesCategory: { nodes: [] } }
        )
      }

      const blocData = await getBlocData({ id, nonce, formatedInitData })

      const aggregatedData = { ...blocData.data, ...articlesData, rates }

      setData(formatWpResultToGatsbyResult(aggregatedData))
    })()
  }, [])

  return data ? <Post data={data} pageContext={{ variant: null }} /> : <div>Loading...</div>
}

PreviewPostBlocs.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default PreviewPostBlocs

export const query = graphql`
  query PreviewBlocsQuery {
    rates(id: { ne: "dummy" }) {
      data {
        lastExecutionDate: last_execution_date
        marketRatesHistory: market_rates_history {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesRegionHistory: market_rates_region_history {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesLatest: market_rates_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesProfileLatest: market_rates_profile_latest {
          averageRate: average_rate
          date
          duration
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesProfileRegionLatest: market_rates_profile_region_latest {
          averageRate: average_rate
          date
          duration
          maxOrdinaryRate: max_ordinary_rate_display
          maxVeryGoodRate: max_very_good_rate_display
          minOrdinaryRate: min_ordinary_rate_display
          minVeryGoodRate: min_very_good_rate_display
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          region
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesRegionLatest: market_rates_region_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
      }
    }
  }
`
