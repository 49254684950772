import { API_URL } from '@pretto/website/config'
import INIT_QUERY from '@pretto/website/src/templates/blocs/queries/preview/initialisation.query'

type GetInitData = {
  id: string
  nonce: string
}

export const getInitData = async ({ id, nonce }: GetInitData) => {
  const fetchInitData = await fetch(API_URL, {
    body: JSON.stringify({
      query: INIT_QUERY,
      variables: { id },
    }),
    credentials: 'include', // Test local: change Access-Control-Allow-Origin in "@pretto/wordpress/web/app/themes/rest-api/inc/graphql.php"
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Wp-Nonce': nonce, // Test local: Remove nonce
    },
    method: 'POST',
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .catch(error => {
      throw new Error(error)
    })

  return fetchInitData
}
