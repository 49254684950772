import { API_URL } from '@pretto/website/config'
import TEMPLATE_BLOCS_QUERY from '@pretto/website/src/templates/blocs/queries/preview/templateBlocs.query'

type GetBlocData = {
  formatedInitData: object
  id: string
  nonce: string
}

export const getBlocData = async ({ formatedInitData, id, nonce }: GetBlocData) => {
  const fetchBlocData = await fetch(API_URL, {
    body: JSON.stringify({
      query: TEMPLATE_BLOCS_QUERY,
      variables: { id, ...formatedInitData },
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Wp-Nonce': nonce,
    },
    method: 'POST',
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .catch(error => {
      throw new Error(error)
    })

  return fetchBlocData
}
