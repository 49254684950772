module.exports.getTemplateBlocsContent = about => {
  if (about?.aboutContentRows) {
    const contentArray = about?.aboutContentRows.filter(row => row.aboutContentRowsContentText)

    if (contentArray.length > 0) {
      return contentArray[0].aboutContentRowsContentText
    }
  }

  return null
}
