import { getTagsInContent } from '@pretto/website/utilities/createPages/postTypes/lib/getTagsInContent'
import { getTemplateBlocsContent } from '@pretto/website/utilities/createPages/postTypes/lib/getTemplateBlocsContent'

const DEFAULT_ARTICLES_CATEGORY_ID = 1

const formatInitData = post => {
  const tags = getTagsInContent(post.content)

  const templateBlocsContent = getTemplateBlocsContent(post.template.about)
  const templateBlocsTags = getTagsInContent(templateBlocsContent)

  const primaryCategoryAncestorsNodes = post.primaryCategory.node.ancestors?.nodes
  const highestAncestorCategoryId =
    primaryCategoryAncestorsNodes?.[primaryCategoryAncestorsNodes.length - 1]?.id ?? post.primaryCategory.node.id

  const templateBlocsArticlesCategories = [
    ...(post.template.about?.aboutContentRows?.map(elem => elem.aboutContentRowsMediaCategory) || []),
    post.template.about?.aboutContentHero?.[0].aboutContentHeroMediaHeroCategory,
  ].filter(Boolean)

  return {
    highestAncestorCategoryId,
    secondaryMenuId: post.template.about?.aboutContentSecondaryMenu?.aboutContentSecondaryMenuId
      ? Number(post.template.about.aboutContentSecondaryMenu.aboutContentSecondaryMenuId)
      : 0,
    shouldIncludeArticlesCategories: templateBlocsArticlesCategories.length > 0,
    shouldIncludeSecondaryMenu:
      !!post.template.about?.aboutContentSecondaryMenu?.aboutContentSecondaryMenuDisplay &&
      !!post.template.about?.aboutContentSecondaryMenu?.aboutContentSecondaryMenuId,
    slug: post.slug,
    tags,
    templateBlocsArticlesCategories,
    templateBlocsArticlesCategoriesId: DEFAULT_ARTICLES_CATEGORY_ID,
    templateBlocsTags,
  }
}

export default formatInitData
