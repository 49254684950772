import { API_URL } from '@pretto/website/config'

const QUERY = `
  query CategoryId($categoriesSlug: [String]) {
    categories(where: { slug: $categoriesSlug }) {
      nodes {
        databaseId
      }
    }
  }
`

type GetCategoryId = {
  categoriesSlug: string[]
  nonce: string
}

export const getCategoryId = async ({ categoriesSlug, nonce }: GetCategoryId) => {
  const fetchCategoriesId = await fetch(API_URL, {
    body: JSON.stringify({
      query: QUERY,
      variables: { categoriesSlug },
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Wp-Nonce': nonce,
    },
    method: 'POST',
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .catch(error => {
      throw new Error(error)
    })

  return fetchCategoriesId
}
