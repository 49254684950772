const formatWpResultToGatsbyResult = data => {
  const formatedData = {
    ...data,
    currentCategory: data.currentCategory?.nodes[0] && {
      ...data.currentCategory.nodes[0],
      wpParent: data.currentCategory.nodes[0] ? data.currentCategory.nodes[0].parent : null,
      wpChildren: data.currentCategory.nodes[0] ? data.currentCategory.nodes[0].children : null,
    },
    wp: {
      acfOptionsHomeSettings: data.acfOptionsHomeSettings,
      acfOptionsRatesSettings: data.acfOptionsRatesSettings,
      writingSettings: data.writingSettings,
    },
    wpPost: {
      ...data.wpPost,
      template: {
        __typename: `Wp${data.wpPost.template.__typename}`,
        about: {
          hero: data.wpPost.template.about.hero.map(({ __typename, ...hero }) => ({
            __typename: `Wp${__typename}`,
            ...hero,
          })),
          rows: data.wpPost.template.about.rows.map(({ __typename, ...row }) => ({
            __typename: `Wp${__typename}`,
            ...row,
          })),
        },
      },
    },
    secondaryMenu: { nodes: [data.secondaryMenu] },
  }

  delete formatedData.acfOptionsHomeSettings
  delete formatedData.acfOptionsRatesSettings
  delete formatedData.writingSettings

  return formatedData
}

export default formatWpResultToGatsbyResult
