const INIT_QUERY = `
  query SetupQuery($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      content
      primaryCategory {
        node {
          ancestors {
            nodes {
              id
            }
          }
          id
        }
      }
      slug
      template {
        __typename
        ... on Template_SimulateurParBanque {
          simulationBank {
            airtableBankSlug: airtablebankslug
          }
        }
        ... on Template_Blocs {
          about {
            aboutContentSecondaryMenu {
              ... on Template_Blocs_About_AboutContentSecondaryMenu {
                aboutContentSecondaryMenuDisplay
                aboutContentSecondaryMenuId
              }
            }
            aboutContentHero {
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroMediaHero {
                aboutContentHeroMediaHeroCategory
              }
            }
            aboutContentRows {
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsContent {
                aboutContentRowsContentText
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsMedia {
                aboutContentRowsMediaCategory
              }
            }
          }
        }
      }
      uri
    }
  }
`

export default INIT_QUERY
