import { API_URL } from '@pretto/website/config'

const QUERY = `
  query CategoryData($categoryId: ID!, $categoryIdInt: Int) {
    articles: posts(where: { categoryId: $categoryIdInt }, first: 10000) {
      nodes {
        uri
        title
        modified
        date
        postTypeMeta {
          highlightedimage
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
    articlesCategory: category(id: $categoryId, idType: DATABASE_ID) {
      name
      slug
    }
  }
`

type GetCategoryId = {
  categoryId: number
  nonce: string
}

export const getCategoryData = async ({ categoryId, nonce }: GetCategoryId) => {
  const fetchCategoryId = await fetch(API_URL, {
    body: JSON.stringify({
      query: QUERY,
      variables: { categoryId, categoryIdInt: categoryId },
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-Wp-Nonce': nonce,
    },
    method: 'POST',
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .catch(error => {
      throw new Error(error)
    })

  return fetchCategoryId
}
