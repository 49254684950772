const TEMPLATE_BLOCS_QUERY = `
  query PreviewBloc(
    $highestAncestorCategoryId: ID!
    $id: ID!
    $secondaryMenuId: ID!
    $shouldIncludeSecondaryMenu: Boolean!
    $slug: String
    $tags: [String!]!
    $templateBlocsTags: [String!]!
  ) {
    wpPost: post(id: $id, idType: DATABASE_ID) {
      author {
        node {
          biography: description
          name
          seo {
            social {
              linkedInUrl: linkedIn
            }
          }
          slug
          userMeta {
            jobTitle
            photoSlug: pictureslug
          }
        }
      }
      comments {
        nodes {
          parentId
          databaseId
          date
          author {
            node {
              name
            }
          }
          content
          replies {
            nodes {
              replyDatabaseId: databaseId
              replyDate: date
              replyAuthor: author {
                node {
                  name
                }
              }
              replyContent: content
            }
          }
        }
      }
      content
      date
      databaseId
      id
      modified
      post {
        options
      }
      postTypeMeta {
        highlightedImage: highlightedimage
        isSidebarMenuActive: issidebarmenuactive
      }
      primaryCategory {
        node {
          ancestors {
            nodes {
              title: name
              url: uri
            }
          }
          databaseId
          id
          name
          posts {
            nodes {
              date
              excerpt
              id
              modified
              postTypeMeta {
                highlightedImage: highlightedimage
              }
              title
              titleVariations {
                variations {
                  content
                }
              }
              uri
            }
          }
          uri
        }
      }
      seo {
        canonical
        description: metaDesc
        noindex: metaRobotsNoindex
        title
      }
      template {
        __typename
        ... on Template_Blocs {
          about {
            hero: aboutContentHero {
              __typename
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroButtonHero {
                description: aboutContentHeroButtonHeroDescription
                image: aboutContentHeroButtonHeroImage
                buttonLabel: aboutContentHeroButtonHeroButtonLabel
                buttonUrl: aboutContentHeroButtonHeroButtonUrl
                promises: aboutContentHeroButtonHeroPromisesEntries {
                  promise: aboutContentHeroButtonHeroPromisesEntriesPromise
                  title: aboutContentHeroButtonHeroPromisesEntriesTitle
                }
              }
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroInputHero {
                arePromisesDisplayed: aboutContentHeroInputHeroInputDisplayPromises
                bannerId: aboutContentHeroInputHeroInputId
                checkMessage: aboutContentHeroInputHeroInputCheckMessage
                image: aboutContentHeroInputHeroImage
                inputPlaceholder: aboutContentHeroInputHeroInputPlaceholder
                isCheckMandatory: aboutContentHeroInputHeroInputIsCheckMandatory
                promises: aboutContentHeroInputHeroPromises {
                  promise: aboutContentHeroInputHeroPromisesPromiseText
                  title: aboutContentHeroInputHeroPromisesPromiseTitle
                }
                successMessage: aboutContentHeroInputHeroInputSuccessMessage
                text: aboutContentHeroInputHeroText
                zap: aboutContentHeroInputHeroInputZapierLink
              }
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroMediaHero {
                category: aboutContentHeroMediaHeroCategory
                isColored: aboutContentHeroMediaHeroOptionsColored
              }
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroPictureHero {
                picture: aboutContentHeroPictureHeroPicture
                isColored: aboutContentHeroPictureHeroOptionsColored
              }
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroHeroRates {
                ctaLabel: aboutContentHeroHeroRatesButtonLabel
                ctaUrl: aboutContentHeroHeroRatesButtonUrl
                help: aboutContentHeroHeroRatesHelp
                mortgageDuration: aboutContentHeroHeroRatesMorgageDuration
                mortgagorsNumber: aboutContentHeroHeroRatesNumberMortgagors
                mortgagorsWages: aboutContentHeroHeroRatesMortgagorsWages
                propertyPrice: aboutContentHeroHeroRatesPropertyPrice
                propertyRegion: aboutContentHeroHeroRatesRegion
                text: aboutContentHeroHeroRatesText
              }
              ... on Template_Blocs_About_AboutContentHero_AboutContentHeroSimulatorHero {
                arePromisesDisplayed: aboutContentHeroButtonHeroPromisesArepromisesdisplayed
                promises: aboutContentHeroButtonHeroPromisesEntries {
                  promise: aboutContentHeroButtonHeroPromisesEntriesPromise
                  title: aboutContentHeroButtonHeroPromisesEntriesTitle
                }
                projectTypes: aboutContentHeroSimulatorHeroProjectKinds
                tabs: aboutContentHeroSimulatorHeroTabs {
                  capacity: aboutContentHeroSimulatorHeroTabsCapacity {
                    tablabel: aboutContentHeroSimulatorHeroTabsCapacityTabLabel
                    ctalabel: aboutContentHeroSimulatorHeroTabsCapacityCtaLabel
                  }
                  purchase: aboutContentHeroSimulatorHeroTabsPurchase {
                    tablabel: aboutContentHeroSimulatorHeroTabsPurchaseTabLabel
                    ctalabel: aboutContentHeroSimulatorHeroTabsPurchaseCtaLabel
                  }
                  renegotiation: aboutContentHeroSimulatorHeroTabsRenegotiation {
                    tablabel: aboutContentHeroSimulatorHeroTabsRenegotiationTabLabel
                    ctalabel: aboutContentHeroSimulatorHeroTabsRenegotiationCtaLabel
                  }
                }
                text: aboutContentHeroSimulatorHeroDescription
              }
            }
            rows: aboutContentRows {
              __typename
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsDefault {
                buttonLabel: aboutContentRowsDefaultButtonLabel
                buttonType: aboutContentRowsDefaultButtonType
                buttonUrl: aboutContentRowsDefaultButtonUrl
                content: aboutContentRowsDefaultContent
                highlightDescription: aboutContentRowsDefaultHighlightDescription
                highlightNumber: aboutContentRowsDefaultHighlightNumber
                image: aboutContentRowsDefaultImage
                isFlipped: aboutContentRowsDefaultFlip
                isHighlightEnabled: aboutContentRowsDefaultHighlightEnable
                isShaded: aboutContentRowsDefaultShade
                suptitle: aboutContentRowsDefaultSuptitle
                title: aboutContentRowsDefaultTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsArticleslink {
                articles: aboutContentRowsArticleslinkArticles {
                  author: aboutContentRowsArticleslinkArticlesAuthor
                  date: aboutContentRowsArticleslinkArticlesDate
                  picture: aboutContentRowsArticleslinkArticlesPicture
                  title: aboutContentRowsArticleslinkArticlesTitle
                  url: aboutContentRowsArticleslinkArticlesUrl
                }
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsCards {
                entries: aboutContentRowsCardsEntries {
                  description: aboutContentRowsCardsEntriesDescription
                  suptitle: aboutContentRowsCardsEntriesSuptitle
                  title: aboutContentRowsCardsEntriesTitle
                  url: aboutContentRowsCardsEntriesUrl
                }
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsCarousel {
                entries: aboutContentRowsCarouselEntries {
                  content: aboutContentRowsCarouselEntriesContent
                  picto
                  title: aboutContentRowsCarouselEntriesTitle
                }
                image: aboutContentRowsCarouselImage
                isFlipped: aboutContentRowsCarouselFlip
                suptitle: aboutContentRowsCarouselSuptitle
                title: aboutContentRowsCarouselTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsContent {
                markdownContent: aboutContentRowsContentText
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsDistribution {
                content: aboutContentRowsDistributionContent
                duration: aboutContentRowsDistributionDuration
                isReversed: aboutContentRowsDistributionOrder
                region: aboutContentRowsDistributionRegion
                title: aboutContentRowsDistributionTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsHeroN1 {
                buttonLabel: aboutContentRowsHeroN1ButtonLabel
                buttonUrl: aboutContentRowsHeroN1ButtonUrl
                checks: aboutContentRowsHeroN1Checks {
                  content
                }
                description: aboutContentRowsHeroN1Description
                image: aboutContentRowsHeroN1Image
                isCheckSectionDisplayed: aboutContentRowsHeroN1IsCheckSectionDisplayed
                isSimulatorDisplayed: aboutContentRowsHeroN1IsSimulatorDisplayed
                isButtonEnabled: aboutContentRowsHeroN1ButtonEnable
                isPromisesEnabled: aboutContentRowsHeroN1PromisesEnable
                promises: aboutContentRowsHeroN1PromisesEntries {
                  picto
                  promise: aboutContentRowsHeroN1PromisesEntriesPromise
                  title: aboutContentRowsHeroN1PromisesEntriesTitle
                }
                projectKind: aboutContentRowsHeroN1ProjectKind
                simulatorTabs: aboutContentRowsHeroN1Tabs {
                  capacity {
                    ctalabel
                    tablabel
                  }
                  purchase {
                    ctalabel
                    tablabel
                  }
                  renegotiation {
                    ctalabel
                    tablabel
                  }
                }
                title: aboutContentRowsHeroN1Title
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsHeroRates {
                ratesHeroRowCta: aboutContentRowsHeroRatesCta
                ratesHeroRowDuration: aboutContentRowsHeroRatesDuration
                ratesHeroRowHelp: aboutContentRowsHeroRatesHelp
                ratesHeroRowMortgagorsNumber: aboutContentRowsHeroRatesMortgagors
                ratesHeroRowMortgagorsWages: aboutContentRowsHeroRatesWages
                ratesHeroRowPropertyPrice: aboutContentRowsHeroRatesPrice
                ratesHeroRowRegion: aboutContentRowsHeroRatesRegion
                ratesHeroRowUrl: aboutContentRowsHeroRatesUrl
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsHeroN2 {
                buttonLabel: aboutContentRowsHeroN2ButtonLabel
                buttonUrl: aboutContentRowsHeroN2ButtonUrl
                checks: aboutContentRowsHeroN2Checks {
                  content
                }
                description: aboutContentRowsHeroN2Description
                image: aboutContentRowsHeroN2Image
                isCheckSectionDisplayed: aboutContentRowsHeroN2IsCheckSectionDisplayed
                isSimulatorDisplayed: aboutContentRowsHeroN2IsSimulatorDisplayed
                promises: aboutContentRowsHeroN2PromisesEntries {
                  picto
                  promise: aboutContentRowsHeroN2PromisesEntriesPromise
                  title: aboutContentRowsHeroN2PromisesEntriesTitle
                }
                projectKind: aboutContentRowsHeroN2ProjectKind
                simulatorTabs: aboutContentRowsHeroN2Tabs {
                  capacity {
                    ctalabel
                    tablabel
                  }
                  purchase {
                    ctalabel
                    tablabel
                  }
                  renegotiation {
                    ctalabel
                    tablabel
                  }
                }
                title: aboutContentRowsHeroN2Title
                type: aboutContentRowsHeroN2Type
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsInput {
                bannerId: aboutContentRowsInputId
                checkMessage: aboutContentRowsInputCheckMessage
                image: aboutContentRowsInputImage
                inputPlaceholder: aboutContentRowsInputPlaceholder
                isCheckMandatory: aboutContentRowsInputIsCheckMandatory
                isColored: aboutContentRowsInputIsColored
                isReversed: aboutContentRowsInputIsReversed
                successMessage: aboutContentRowsInputSuccessMessage
                suptitle: aboutContentRowsInputSuptitle
                text: aboutContentRowsInputText
                title: aboutContentRowsInputTitle
                zap: aboutContentRowsInputZap
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsInputBanner {
                bannerId: aboutContentRowsInputBannerId
                illustration: aboutContentRowsInputBannerIllustration
                inputPlaceholder: aboutContentRowsInputBannerInputPlaceholder
                isHero: aboutContentRowsInputBannerIshero
                promises: aboutContentRowsInputBannerPromisesEntries {
                  picto
                  promise: aboutContentRowsInputBannerPromisesEntriesPromise
                  title: aboutContentRowsInputBannerPromisesEntriesTitle
                }
                successMessage: aboutContentRowsInputBannerSuccessMessage
                text: aboutContentRowsInputBannerText
                title: aboutContentRowsInputBannerTitle
                zap: aboutContentRowsInputBannerZap
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsInfo {
                buttonLabel: aboutContentRowsInfoButtonLabel
                buttonUrl: aboutContentRowsInfoButtonUrl
                content: aboutContentRowsInfoContent
                image: aboutContentRowsInfoImage
                title: aboutContentRowsInfoTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsLabels {
                labels: aboutContentRowsLabelsEntries {
                  content: aboutContentRowsLabelsEntriesContent
                  status: aboutContentRowsLabelsEntriesStatus {
                    label: aboutContentRowsLabelsEntriesStatusLabel
                    theme: aboutContentRowsLabelsEntriesStatusTheme
                  }
                  title: aboutContentRowsLabelsEntriesTitle
                }
                suptitle: aboutContentRowsLabelsSuptitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsLinks {
                links: aboutContentRowsLinksLinks {
                  button: aboutContentRowsLinksLinksButton {
                    label: aboutContentRowsLinksLinksButtonLabel
                    url: aboutContentRowsLinksLinksButtonUrl
                  }
                  description: aboutContentRowsLinksLinksDescription
                  picto
                  title: aboutContentRowsLinksLinksTitle
                }
                title: aboutContentRowsLinksTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsPictos {
                buttonLabel: aboutContentRowsPictosButtonLabel
                buttonType: aboutContentRowsPictosButtonType
                buttonUrl: aboutContentRowsPictosButtonUrl
                content: aboutContentRowsPictosContent
                pictos: aboutContentRowsPictosEntries {
                  description: aboutContentRowsPictosEntriesDescription
                  picto
                }
                suptitle: aboutContentRowsPictosSuptitle
                title: aboutContentRowsPictosTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsProfil {
                profilRowTitle: aboutContentRowsProfilTitle
                profilRowContent: aboutContentRowsProfilContent
                profilRowDuration: aboutContentRowsProfilDuration
                profilRowMortgagors: aboutContentRowsProfilMortgagors
                profilRowIsReversed: aboutContentRowsProfilOrder
                profilRowRegion: aboutContentRowsProfilRegion
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsAnalysis {
                analysisRowDuration: aboutContentRowsAnalysisDuration
                analysisRowIsReversed: aboutContentRowsAnalysisOrder
                analysisRowRegion: aboutContentRowsAnalysisRegion
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsRegions {
                regionsRowTitle: aboutContentRowsRegionsTitle
                regionsRowContent: aboutContentRowsRegionsContent
                regionsRowDuration: aboutContentRowsRegionsDuration
                regionsRowIsReversed: aboutContentRowsRegionsOrder
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsTabs {
                suptitle: aboutContentRowsTabsSuptitle
                tabs: aboutContentRowsTabsTabs {
                  tabContent: aboutContentRowsTabsTabsTabcontent
                  tabTitle: aboutContentRowsTabsTabsTabtitle
                }
                title: aboutContentRowsTabsTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsSimulator {
                simulatorTitle: aboutContentRowsSimulatorTitle
                simulatorTitleTag: aboutContentRowsSimulatorTag
                simulatorType: aboutContentRowsSimulatorType
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsTimeline {
                buttonLabel: aboutContentRowsTimelineButtonLabel
                buttonType: aboutContentRowsTimelineButtonType
                buttonUrl: aboutContentRowsTimelineButtonUrl
                entries: aboutContentRowsTimelineEntries {
                  entry: aboutContentRowsTimelineEntriesEntry
                }
                image: aboutContentRowsTimelineImage
                isFlipped: aboutContentRowsTimelineFlip
                preamble: aboutContentRowsTimelinePreamble
                suptitle: aboutContentRowsTimelineSuptitle
                title: aboutContentRowsTimelineTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsVideo {
                buttonLabel: aboutContentRowsVideoButtonLabel
                buttonUrl: aboutContentRowsVideoButtonUrl
                content: aboutContentRowsVideoContent
                video: aboutContentRowsVideoVideo
                title: aboutContentRowsVideoTitle
                subtitle: aboutContentRowsVideoSubtitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsCards {
                entries: aboutContentRowsCardsEntries {
                  description: aboutContentRowsCardsEntriesDescription
                  image: aboutContentRowsCardsEntriesPicture
                  suptitle: aboutContentRowsCardsEntriesSuptitle
                  title: aboutContentRowsCardsEntriesTitle
                  url: aboutContentRowsCardsEntriesUrl
                }
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsCarousel {
                entries: aboutContentRowsCarouselEntries {
                  content: aboutContentRowsCarouselEntriesContent
                  picto
                  title: aboutContentRowsCarouselEntriesTitle
                }
                image: aboutContentRowsCarouselImage
                isColored: aboutContentRowsCarouselOptionsColored
                isReversed: aboutContentRowsCarouselFlip
                suptitle: aboutContentRowsCarouselSuptitle
                title: aboutContentRowsCarouselTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsContent {
                markdownContent: aboutContentRowsContentText
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsMedia {
                category: aboutContentRowsMediaCategory
                isColored: aboutContentRowsMediaOptionsColored
                suptitle: aboutContentRowsMediaSuptitle
                title: aboutContentRowsMediaTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsHeroRates {
                ctaLabel: aboutContentRowsHeroRatesCta
                ctaUrl: aboutContentRowsHeroRatesUrl
                help: aboutContentRowsHeroRatesHelp
                isColored: aboutContentRowsHeroRatesOptionsColored
                legend: aboutContentRowsHeroRatesGeneralLegend
                mortgageDuration: aboutContentRowsHeroRatesDuration
                mortgagorsNumber: aboutContentRowsHeroRatesMortgagors
                mortgagorsWages: aboutContentRowsHeroRatesWages
                propertyPrice: aboutContentRowsHeroRatesPrice
                propertyRegion: aboutContentRowsHeroRatesRegion
                suptitle: aboutContentRowsHeroRatesGeneralSuptitle
                text: aboutContentRowsHeroRatesGeneralText
                title: aboutContentRowsHeroRatesGeneralTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsDefault {
                buttonLabel: aboutContentRowsDefaultButtonLabel
                buttonType: aboutContentRowsDefaultButtonType
                buttonUrl: aboutContentRowsDefaultButtonUrl
                content: aboutContentRowsDefaultContent
                highlightDescription: aboutContentRowsDefaultHighlightDescription
                highlightValue: aboutContentRowsDefaultHighlightNumber
                image: aboutContentRowsDefaultImage
                isColored: aboutContentRowsDefaultOptionsColored
                isReversed: aboutContentRowsDefaultFlip
                suptitle: aboutContentRowsDefaultSuptitle
                title: aboutContentRowsDefaultTitle
              }
              ... on Template_Blocs_About_AboutContentRows_AboutContentRowsTimeline {
                buttonLabel: aboutContentRowsTimelineButtonLabel
                buttonType: aboutContentRowsTimelineButtonType
                buttonUrl: aboutContentRowsTimelineButtonUrl
                entries: aboutContentRowsTimelineEntries {
                  entry: aboutContentRowsTimelineEntriesEntry
                }
                image: aboutContentRowsTimelineImage
                isColored: aboutContentRowsTimelineOptionsColored
                isReversed: aboutContentRowsTimelineFlip
                preamble: aboutContentRowsTimelinePreamble
                suptitle: aboutContentRowsTimelineSuptitle
                title: aboutContentRowsTimelineTitle
              }
            }
          }
        }
      }
      slug
      title
      uri
    }
    secondaryMenu: menu(id: $secondaryMenuId, idType: DATABASE_ID) @include(if: $shouldIncludeSecondaryMenu) {
      menuItems {
        nodes {
          uri
          label
        }
      }
    }
    acfOptionsRatesSettings {
      ratesSettings {
        news: monthlyUpdateNews {
          ... on Post {
            date
            excerpt
            uri
          }
        }
        title: monthlyUpdateTitle
      }
    }
    acfOptionsHomeSettings {
      topBanner: homeSettings {
        content: homeSettingsBannerContent
        isDisplayed: homeSettingsBannerIsDisplayed
        location: homeSettingsBannerLocation
      }
    }
    writingSettings {
      defaultCategory
    }
    allWpTag: tags(where: { name: $tags }) {
      nodes {
        name
        posts {
          nodes {
            categories {
              nodes {
                databaseId
              }
            }
            databaseId
            date
            modified
            title
            uri
          }
        }
      }
    }
    allTemplateBlocsTag: tags(where: { name: $templateBlocsTags }) {
      nodes {
        name
        posts {
          nodes {
            categories {
              nodes {
                databaseId
              }
            }
            databaseId
            date
            modified
            title
            uri
          }
        }
      }
    }
    writingSettings {
      defaultCategory
    }
    currentCategory: categories(where: { slug: [$slug] }) {
      nodes {
        id
        name
        posts {
          nodes {
            date
            excerpt
            modified
            postTypeMeta {
              highlightedImage: highlightedimage
            }
            title
            titleVariations {
              variations {
                content
              }
            }
            uri
          }
        }
        uri
        children {
          nodes {
            path: uri
            title: name
          }
        }
        parent {
          node {
            posts {
              nodes {
                id
                path: uri
                title
                titleVariations {
                  variations {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
    highestCategory: category(id: $highestAncestorCategoryId) {
      databaseId
      name
    }
  }
`

export default TEMPLATE_BLOCS_QUERY
